import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { ApplicationState, ApplicationStateModel } from '../../app.state';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  FeaturePermission,
  FeaturePermissions,
  isFeatureEnabled,
} from '../../../../../server/src/companies/model/feature-permissions.interface';

@UntilDestroy()
@Directive({
  selector: '[featureAvailable]',
  standalone: true,
})
export class FeatureAvailableDirective implements OnInit, OnChanges {
  private _featurePermissions?: FeaturePermissions;
  private _isInView = false;

  constructor(
    private _element: ElementRef,
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _changeDetector: ChangeDetectorRef) {
  }

  @Input()
  public featureAvailable?: keyof typeof FeaturePermission;

  @Select(ApplicationState)
  public applicationState$!: Observable<ApplicationStateModel>;

  public ngOnInit() {
    this.applicationState$
      .pipe(
        untilDestroyed(this),
      )
      .subscribe((state) => {
        this._featurePermissions = state.featurePermissions;

        this._setView();
      });
  }

  public ngOnChanges() {
    this._setView();
  }

  private _setView() {
    const shouldShow = this.featureAvailable && isFeatureEnabled(FeaturePermission[this.featureAvailable], this._featurePermissions);

    if (shouldShow && !this._isInView) {
      this._viewContainer.createEmbeddedView(this._templateRef);
      this._isInView = true;
    } else if (!shouldShow && this._isInView) {
      this._viewContainer.clear();
      this._isInView = false;
    }

    this._changeDetector.markForCheck();
  }
}
